import styled from "styled-components"

export const Wrapper = styled.footer`
  margin-top: 100px;
  p,
  a {
    color: ${props => props.theme.colors.lightText};
    font-size: 1.6rem;
  }
`
