import React from "react"
import { Navbar, Nav } from "react-bootstrap"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container } from "../../elements"
import styled from "styled-components"

const StyledLogo = styled(Img)`
  width: 250px;

  @media screen and (max-width: 576px) {
    width: 200px;
  }
`

const StyledNavbar = styled(Navbar)`
  border-bottom: ${props => props.theme.colors.primary} 4px solid;
  box-sizing: border-box;
  .navbar-nav .nav-link {
    color: ${props => props.theme.colors.text};
    transition-duration: 300ms;
    :hover {
      color: ${props => props.theme.colors.primary};
    }
    :focus {
      color: ${props => props.theme.colors.primary};
    }
  }
  .highlight {
    .nav-link {
      color: white;
      font-weight: 500;
      font-size: 1.5rem;
      background-color: ${props => props.theme.colors.primary};
      padding: 10px 30px;
      display: inline-block;
    }
  }
`

export const Header = props => {
  const data = useStaticQuery(graphql`
    query {
      fileName: contentfulSetting(name: { eq: "logo" }) {
        image {
          fluid(quality: 90, maxWidth: 250) {
            ...GatsbyContentfulFluid
          }
        }
        name
      }
      allContentfulCategories(sort: { fields: [position], order: ASC }) {
        nodes {
          id
          name
        }
      }
    }
  `)

  return (
    <StyledNavbar
      collapseOnSelect
      expand="lg"
      bg="white"
      className="mb-lg-5 p-4"
      fixed={"top"}
    >
      <Container>
        <Link className="navbar-brand" to="/">
          <StyledLogo
            src={data.fileName.name}
            {...props}
            fluid={data.fileName.image.fluid}
          />
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto align-items-lg-center">
            {data.allContentfulCategories.nodes.map(c => {
              return (
                <Nav.Item key={c.id}>
                  <Link
                    className="nav-link ml-2 mr-2"
                    to={`/#${c.name.toLowerCase()}`}
                  >
                    {c.name}
                  </Link>
                </Nav.Item>
              )
            })}
            <Nav.Item className="d-none d-lg-block">
              <Link className="nav-link ml-2 mr-2" to={`/#realisations`}>
                Réalisations
              </Link>
            </Nav.Item>
            <Nav.Item className="highlight ml-2">
              <Link className="nav-link" to="/#contact">
                Devis
              </Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </StyledNavbar>
  )
}
