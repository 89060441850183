import React from "react"
import { Wrapper } from "./style"
import { Row } from "react-bootstrap"
import { Container } from "../../elements"
import { Link } from "gatsby"

export const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <Row className="justify-content-lg-between justify-content-center m-0">
          <p>Aerotig</p>
          <Row className="justify-content-center">
            <Link className="mr-5" to={`/mentions-legales`}>
              <p>Mentions Légales</p>
            </Link>
            <p>©{new Date().getFullYear()} tous droits réservés</p>
          </Row>
        </Row>
      </Container>
    </Wrapper>
  )
}
