import React from "react"
import { Header, Footer } from "../../semantics"
import { Main } from "./styles"

export const MainLayout = ({ children }) => {
  return (
    <>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </>
  )
}
