import styled from "styled-components"

export const Wrapper = styled.section`
  padding-top: ${props => (props.nopt ? 0 : "100px")};
  padding-bottom: ${props => (props.nopb ? 0 : "100px")};
  @media screen and (max-width: 576px) {
    padding-top: ${props => (props.nopt ? 0 : "40px")};
    padding-bottom: ${props => (props.nopb ? 0 : "40px")};
  }
`
