import styled from "styled-components"

export const H1 = styled.h1`
  font-size: 7rem;
  font-weight: 400;
  line-height: 88px;
  @media screen and (max-width: 576px) {
    font-size: 4rem;
    line-height: 45px;
    margin-bottom: 30px;
  }
`
export const H2 = styled.h2`
  font-size: 5rem;
  font-weight: 300;
  @media screen and (max-width: 576px) {
    font-size: 3rem;
    line-height: 35px;
  }
`
export const H3 = styled.h3`
  color: ${props => props.theme.colors.text};
  font-size: 4rem;
  font-weight: 700;
  line-height: 60px;
  @media screen and (max-width: 576px) {
    font-size: 3rem;
    line-height: 40px;
    margin-bottom: 20px;
  }
`
export const H4 = styled.h4``
export const H5 = styled.h5``
export const H6 = styled.h6``
